
import Watermark from "@/utils/watermark";
import { getManualDetailH5 } from "@/views/center/operatingManual/api";
export default {
  name: "",
  data() {
    return {
      isMobile:false,
      waterMark: null,// 水印
      item:{},
      id:''
    }
  },
  mounted() {
    this.$nextTick(() => {
      $('#privacy-box').css("font-size",  (document.documentElement.clientWidth / 1920)*100 + 'px' );
      const sWidth = document.documentElement.clientWidth;
      if(sWidth < 768){
        this.isMobile = true
      }else{
        this.isMobile = false;
      }
    });

    this.getDetail()
    Watermark.set(this.getQueryString().account,this.$refs.watermarkDiv,this.$util.getNowDate())

  },
  watch:{

  },
  beforeDestroy() {
    Watermark.set("")
  },
  methods: {
    // 获取详情
    getDetail(){
      getManualDetailH5({...this.getQueryString()}).then(res=>{
        this.pageLoading=false
        if(res.data.code == 0){
          this.item = res.data.data
          document.title= this.item.manualMenuName? this.item.manualMenuName:''
        }else{
          this.$message.warning(res.data.msg)
          this.item = {}
        }
      })
    },
    // getQueryString(name, url) {
    //   let u = arguments[1] || window.location.href,
    //       reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
    //       r = u.substr(u.indexOf("?") + 1).match(reg);
    //   return r != null ? decodeURI(r[2]) : "";
    // },
    getQueryString(){
      let url =  window.location.href;
      let str = url.split("?")[1];
      let item = str.split("&");
      let obj = {};
      for(let i=0;i<item.length;i++){
        let arr = item[i].split("=");
        obj[arr[0]] = arr[1];
      }
      return obj
    }


  }
}